import React from "react"
import { Helmet } from "react-helmet"

const NotFoundPage = () => (
  <div>
    <Helmet title="klokkefeil">
      <meta name="robots" content="noindex"></meta>
    </Helmet>
    <div
      style={{
        border: `2px solid red`,
        borderRadius: 3,
        padding: 16,
        marginTop: 32,
      }}
    >
      {" "}
      <h3 style={{ marginTop: 0, marginBottom: 32 }}>NB!</h3>
      <p>
        Klokken på PC'en din går sannsynligvis feil. Det kan være grunnen til at
        du ikke får logget inn.
      </p>
      <p>Still klokken riktig, og prøv å logge inn igjen.</p>
      <p>Fremdeles problemer? Kontakt oss på support.</p>
    </div>
  </div>
)

export default NotFoundPage
